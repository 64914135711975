import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocalStorage, useSessionStorage } from "react-use";

import {
  Badge,
  Button,
  ContentLayout,
  Header,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";

import {
  batchFromName,
  fetchBatches,
  subscribeToBatches,
} from "../api/batches";
import { getLatestGravitySample } from "../api/sample";
import DisplayImage from "../components/DisplayImage";
import { useAuthContext } from "../contexts/AuthContext";
import { Batch, BatchViewModel } from "../models/Batch";
import { GravitySample } from "../models/Sampling";
import { costPerUnit } from "../utils/cost";
import { daysUntilDate } from "../utils/date";
import {
  calculateABV,
  calculateDateDay,
  calculateFinalABV,
} from "../utils/steps";
import { addDaysBySteps, toDate } from "../utils/timestamp";
import { renderStars } from "./BatchPage";

const Batches = () => {
  const { tenant } = useAuthContext();
  const navigate = useNavigate();
  const [batches, setBatches] = useState<Batch[]>([]);
  const [sampleRecords, setSampleRecords] = useState<
    { sample: GravitySample; id: string }[]
  >([]);
  const [batchesViewModel, setBatchesViewModel] = useState<BatchViewModel[]>(
    []
  );
  const [isDescending, setIsDescending] = useSessionStorage(
    "isDescending_batch",
    false
  );
  const [sortingColumn, setSortingColumn] = useSessionStorage<keyof Batch>(
    "sortingColumn_batch",
    "batchId"
  );

  const [showArchived, setShowArchived] = useLocalStorage(
    "showArchived",
    false
  );

  useEffect(() => {
    fetchBatches(tenant).then(setBatches);
    const unsubscribe = () => subscribeToBatches(setBatches, tenant);
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    fetchBatches(tenant, sortingColumn, !isDescending).then(setBatches);
  }, [tenant, isDescending, sortingColumn]);

  useEffect(() => {
    const fetchSamples = async () => {
      const batchesWithId = await Promise.all(
        batches.map(async (batch) => {
          const sample = await getLatestGravitySample(batch.id, tenant);
          return { sample, id: batch.id };
        })
      );
      setSampleRecords(batchesWithId);
    };
    fetchSamples();
  }, [batches]);

  // Calculate view model
  useEffect(() => {
    const viewModel = batches.map((batch) => {
      const sampleRecord = sampleRecords.find((s) => s.id === batch.id);
      const sample = sampleRecord ? sampleRecord.sample : null;
      const abvNow = sample ? calculateABV(sample) + "%" : "";
      const abvFinal = sample ? calculateFinalABV(sample) + "%" : "";
      const currentStepName =
        batch.recipe?.steps[batch.currentStepIndex]?.name ?? "N/A";

      let nextActionDate = "N/A";
      let ndate = !!batch.recipe?.steps[batch.currentStepIndex]?.end
        ? toDate(batch.recipe?.steps[batch.currentStepIndex].end ?? new Date())
        : null;
      // if date is null, calculate it from the current step duration
      if (!ndate) {
        ndate = addDaysBySteps(
          toDate(batch.startDate),
          (batch?.recipe?.steps ?? []).slice(
            0,
            (batch.currentStepIndex ?? 0) + 1
          )
        );
      }
      nextActionDate =
        daysUntilDate(ndate).toFixed(0) !== "-1"
          ? daysUntilDate(ndate).toFixed(0) + "d"
          : "✅";

      const date = sample ? toDate(sample.date) : new Date();
      const progress = calculateDateDay(batch, date).progress;
      const costL = costPerUnit(batch).toFixed(2);

      return {
        ...batch,
        abvNow,
        abvFinal,
        currentStepName,
        progress,
        costL,
        nextActionDate,
      };
    });
    setBatchesViewModel(viewModel);
  }, [batches, sampleRecords]);

  const columnDefinitions = [
    {
      header: "Batch",
      cell: (item: BatchViewModel) => (
        <strong>{item.batchId || batchFromName(item.name) || ""}</strong>
      ),
      isRowHeader: true,
      sortingField: "batchId",
    },
    {
      header: "Image",
      cell: (item: BatchViewModel) => (
        <DisplayImage
          fileName="image"
          filePath={"recipes/" + item.recipeId}
          round
          altText="Recipe image"
          style={{ height: 50, width: 50 }}
        />
      ),
    },
    {
      header: "Name",
      cell: (item: BatchViewModel) => (
        <Link to={`/batches/${item.id}`}>
          <strong>{item.name ?? "N/A"}</strong>
        </Link>
      ),
      isRowHeader: true,
      sortingField: "name",
    },
    {
      header: "Status",
      cell: (item: BatchViewModel) => (
        <Badge
          color={
            item.status === "in-progress"
              ? "blue"
              : item.status === "completed"
              ? "green"
              : "grey"
          }
        >
          <span style={{ whiteSpace: "nowrap" }}>
            {(
              (item?.status ?? "planned").charAt(0).toUpperCase() +
              (item?.status ?? "planned").slice(1)
            ).replace("-", " ")}
          </span>
        </Badge>
      ),
    },
    {
      header: "ABV (now)",
      cell: (item: BatchViewModel) => <>{item.abvNow}</>,
    },
    {
      header: "ABV",
      cell: (item: BatchViewModel) => <>{item.abvFinal}</>,
    },
    {
      header: "Next action",
      cell: (item: BatchViewModel) => (
        <>{item.nextActionDate}</> // Directly use the pre-computed nextActionDate
      ),
    },
    {
      header: "Step",
      cell: (item: BatchViewModel) => <>{item.currentStepName}</>,
      sortingField: "currentStepIndex",
    },
    {
      header: "Score",

      cell: (item: BatchViewModel) =>
        (item.score ?? -1) > 0 ? renderStars(item.score ?? 0) : "",
      minWidth: 120,
    },
    {
      header: "Recipe",
      cell: (item: BatchViewModel) => <>{item.recipe?.name ?? "N/A"}</>,
    },
    {
      header: "Quantity",
      cell: (item: BatchViewModel) => <>{item.quantity ?? "N/A"}</>,
    },
    {
      header: "Cost L",
      cell: (item: BatchViewModel) => (
        <span style={{ whiteSpace: "nowrap" }}>€{item.costL}</span>
      ),
    },
    {
      header: "Started",
      cell: (item: BatchViewModel) => {
        const date = toDate(item.startDate);
        return <>{date?.toLocaleDateString() ?? ""}</>;
      },
    },
    {
      header: "Completed",
      cell: (item: BatchViewModel) => (
        <>{item?.endDate ? toDate(item.endDate)?.toLocaleDateString() : ""}</>
      ),
    },
  ];

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h1"
          description={"Hint: Have the recepies and ingredients ready"}
          actions={
            <SpaceBetween size="m" direction="horizontal">
              <Button
                iconName="add-plus"
                variant="primary"
                onClick={() => navigate("/batches/new")}
              >
                Start a batch
              </Button>
              {showArchived ? (
                <Button
                  variant="primary"
                  iconName="check"
                  onClick={() => setShowArchived(false)}
                >
                  Hide archived
                </Button>
              ) : (
                <Button onClick={() => setShowArchived(true)}>
                  Show archived
                </Button>
              )}
            </SpaceBetween>
          }
        >
          Batches
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <Table
          wrapLines
          stickyHeader
          variant="embedded"
          items={
            showArchived
              ? batchesViewModel
              : batchesViewModel.filter((b) => b.status !== "archived")
          }
          onSortingChange={({ detail }) => {
            setIsDescending(detail?.isDescending ?? false);
            setSortingColumn(
              (detail?.sortingColumn.sortingField as keyof Batch) ?? "batchId"
            );
          }}
          sortingDescending={isDescending}
          sortingColumn={columnDefinitions.find(
            (cd) => cd.sortingField === sortingColumn
          )}
          columnDefinitions={columnDefinitions}
        />
      </div>
    </ContentLayout>
  );
};

export default Batches;
